<template>
  <fragment>
    <wx-form-container class="wizard form-style">
      <!-- <v-form ref="productForm" lazy-validation> -->
      <h1 class="main-title">{{ productTitle }}</h1>
      <div class="d-flex flex-column" style="gap: var(--box-padding-admin)">
        <!-- - - - - - - - -       Details Section       - - - - - - - - - - - -->
        <v-card class="product-section-card">
          <v-card-text>
            <v-expansion-panels v-model="detailsAccordionOpen" class="expansion-panel-style" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("product.wizard.details") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="detailsForm" lazy-validation>
                    <fieldset>
                      <v-row>
                        <v-col cols="12">
                          <!-- - - - - - - - - - - - -     Sku     - - - - - - - - - - - - - - - -->
                          <wx-text-field
                            v-model.trim="sku"
                            :label="$t('product.details.sku') + ' *'"
                            :rules="[() => validateSku()]"
                            :counter="skuMaxLength"
                            :maxlength="skuMaxLength"
                            :disabled="!isCreation"
                          />
                        </v-col>
                        <v-col cols="12">
                          <!-- - - - - - - - - - - -       Name       - - - - - - - - - - - - - - -->
                          <wx-text-field
                            v-model.trim="name"
                            :rules="[() => validateName()]"
                            :label="$t('common.name')"
                            :counter="productNameMaxLength"
                            :maxlength="productNameMaxLength"
                          />
                        </v-col>
                        <v-col cols="12">
                          <!-- - - - - - - - - - - -     Category     - - - - - - - - - - - - - - -->
                          <wx-text-field
                            v-model.trim="category"
                            :rules="[() => validateCategory()]"
                            :label="$t('product.wizard.category')"
                            :counter="productCategoryMaxLength"
                            :maxlength="productCategoryMaxLength"
                          />
                        </v-col>
                      </v-row>
                    </fieldset>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <!-- - - - - - - - -       Production Units Section       - - - - - - - - - - - -->
        <v-card class="product-section-card">
          <v-card-text>
            <v-expansion-panels class="expansion-panel-style" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("productionUnit.title") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="productionUnitsForm" lazy-validation>
                    <!-- create the production units associated to the current the product -->
                    <ul class="associated-pu-list">
                      <li
                        class="pu-item"
                        v-for="(associatedPU, index) in associatedProductionUnits"
                        :key="'production-unit' + index"
                      >
                        <v-row>
                          <v-col cols="12">
                            <!-- - - - - - - - -     Production Unit Name     - - - - - - - - - - -  -->
                            <header class="legend-header">
                              <h3 class="wx-typo-h2">{{ $t("productionUnit.titleEdition") }} {{ index + 1 }}</h3>
                              <v-chip
                                class="ma-2"
                                color="secondary"
                                x-small
                                v-if="isProductionUnitSelected(associatedPU)"
                                >{{ getPackages(associatedPU) }}</v-chip
                              >
                              <wx-btn-standard
                                class="btn-delete"
                                text
                                @click="deleteLine(index)"
                                :disabled="isProductAssociatedToOneProductionUnit()"
                                :title="$t('product.wizard.deletion.buttonRemoveLineHoverTitle')"
                              >
                                <v-icon left>mdi-close</v-icon>
                                {{ $t("product.wizard.deletion.buttonRemove") }}
                              </wx-btn-standard>
                            </header>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <wx-select
                              v-model="associatedPU.productionUnit"
                              class="huge-field-width single-field-row"
                              :value="associatedPU.productionUnit.id"
                              :items="currentProductionUnits"
                              item-text="name"
                              item-value="id"
                              :label="$t('productionUnit.titleEdition') + ' *'"
                              :rules="[
                                () => isProductionUnitMissing(associatedPU.productionUnit.id),
                                isSelectedProductionUnitDuplicated(associatedPU.productionUnit.id),
                              ]"
                              @change="
                                (selectedProductionUnit) => onSelectedProductionUnit(selectedProductionUnit, index)
                              "
                              return-object
                            />
                          </v-col>
                        </v-row>

                        <section class="convert-factor-objective-container">
                          <!-- - - - - - - - -     Conversion Factor     - - - - - - - - - - -  -->
                          <fieldset class="inline-fields">
                            <legend>
                              {{ $t("product.details.conversionFactor") }}
                              <wx-contextualized-help
                                :tooltip-text="$t('product.wizard.conversionFactorSubSectionTooltip')"
                              />
                            </legend>
                            <v-row>
                              <v-col class="field-col" cols="6">
                                <wx-text-field
                                  v-model.number="associatedPU.conversionFactorCount"
                                  :label="$t('product.details.conversionFactorCapturedQuantity') + ' *'"
                                  :suffix="$t('product.details.conversionFactorCount')"
                                  type="number"
                                  :rules="[() => validateConversionFactorCount(associatedPU.conversionFactorCount)]"
                                />
                              </v-col>
                              <v-col class="field-col" cols="6">
                                <!-- TODO: the product conversion factor unity should be determined later. -->
                                <wx-text-field
                                  v-model.number="associatedPU.conversionFactorQuantity"
                                  class="equal-before"
                                  :label="$t('product.details.conversionFactorRealQuantity') + ' *'"
                                  :suffix="getConvertedUnitName(associatedPU)"
                                  type="number"
                                  :rules="[
                                    () => validateConversionFactorRealQuantity(associatedPU.conversionFactorQuantity),
                                  ]"
                                />
                              </v-col>
                              <v-col class="field-col" cols="6">
                                <!-- - - - - - - -    Product Objective per PU    - - - - - - - - -  -->
                                <ul class="objectives-list">
                                  <li v-for="objective in associatedPU.objectives" :key="objective.objectiveType">
                                    <wx-text-field
                                      v-if="
                                        isRateObjective(objective.objectiveType) &&
                                          isRateObjectiveAuthorized(associatedPU.productionUnit.id)
                                      "
                                      v-model="objective.objectiveValue"
                                      type="text"
                                      :label="$t('product.wizard.update.targetRatePerHour')"
                                      :suffix="getRateObjectiveUnitName(associatedPU)"
                                      :rules="[() => validateRateObjectiveValue(objective.objectiveValue)]"
                                    />
                                    <wx-text-field
                                      v-if="
                                        isGiveawayObjective(objective.objectiveType) &&
                                          isGiveawayObjectiveAuthorized(associatedPU.productionUnit.id)
                                      "
                                      v-model.number="objective.objectiveValue"
                                      type="number"
                                      min="0"
                                      max="99999"
                                      :label="$t('product.wizard.update.targetGiveawayPercentage')"
                                      suffix="%"
                                      :rules="[() => validateGiveawayObjectiveValue(objective.objectiveValue)]"
                                    />
                                  </li>
                                </ul>
                              </v-col>
                            </v-row>
                          </fieldset>
                        </section>
                      </li>
                    </ul>
                  </v-form>
                  <!-- - - - - - - - -       Production Units       - - - - - - - - - - - -->
                  <footer class="bottom-add-row d-flex flex-row-reverse">
                    <wx-btn-standard
                      text
                      @click="addNewLine"
                      :title="$t('product.wizard.creation.buttonAddNewLineHoverTitle')"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      {{ $t("product.wizard.creation.buttonAddNewLine") }}
                    </wx-btn-standard>
                  </footer>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <!-- - - - - - - - -       Units of Conversion Section       - - - - - - - - - - - -->
        <v-card class="product-section-card">
          <v-card-text>
            <v-expansion-panels class="expansion-panel-style" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("product.wizard.unitsConversionTitle") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="unitConversionForm" lazy-validation>
                    <!----------------- Conversion Factor ----------------->
                    <section class="product-target-row">
                      <fieldset class="inline-fields" v-if="unitConversions.length !== 0">
                        <span>{{ $t("product.wizard.countedUnitsConversionTitle") }}</span>
                        <v-list>
                          <!-- Dinamically create the conversion chain rows -->
                          <v-list-item
                            v-for="(conversion, unitChainConversionIndex) in unitConversions"
                            :key="'conversion-chain' + unitChainConversionIndex"
                          >
                            <v-row align="center">
                              <!-- From factor -->
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <wx-text-field
                                  v-model.number="conversion.from_factor"
                                  :label="$t('product.wizard.quantity')"
                                  :rules="[() => validateFactorConversion(conversion.from_factor)]"
                                  min="0"
                                  max="99999"
                                  type="number"
                                >
                                </wx-text-field>
                              </v-col>
                              <!-- From unit -->
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <wx-autocomplete
                                  v-model="conversion.from_unit"
                                  :items="availableUnitNames(unitChainConversionIndex)"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('productionUnit.details.convertedUnitName')"
                                  :rules="[() => validateUnitConversion(conversion.from_unit, conversion.to_unit)]"
                                  :disabled="unitChainConversionIndex !== 0 || unitConversions.length > 1"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col cols="12" md="1" lg="1" xl="1" align="center">
                                <p><b>=</b></p>
                              </v-col>
                              <!-- To factor -->
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <wx-text-field
                                  v-model.number="conversion.to_factor"
                                  :label="$t('product.wizard.quantity')"
                                  :rules="[() => validateFactorConversion(conversion.to_factor)]"
                                  min="0"
                                  max="99999"
                                  type="number"
                                >
                                </wx-text-field>
                              </v-col>
                              <!-- To Unit -->
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <wx-autocomplete
                                  v-model="conversion.to_unit"
                                  :items="availableUnitNames()"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('productionUnit.details.convertedUnitName')"
                                  :rules="[() => validateUnitConversion(conversion.to_unit, conversion.from_unit)]"
                                  :disabled="unitChainConversionIndex !== unitConversions.length - 1"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col v-if="$vuetify.breakpoint.smAndDown">
                                <hr />
                              </v-col>
                              <v-col
                                class="d-flex field-col"
                                :class="{
                                  'justify-center': $vuetify.breakpoint.smAndDown,
                                  'justify-end': $vuetify.breakpoint.mdAndUp,
                                }"
                                cols="12"
                                md="1"
                                lg="1"
                                xl="1"
                              >
                                <wx-btn-standard
                                  color="transparent"
                                  class="mb-5 mr-0 pr-0 pl-0 ml-0"
                                  v-if="unitChainConversionIndex === unitConversions.length - 1"
                                  @click="removeUnitConversion"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </wx-btn-standard>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list>

                        <!-- Alert for circular unit chains -->
                        <v-row justify="center" v-if="showCircularDependencyError">
                          <v-col cols="auto">
                            <v-alert bordered type="error" text outlined>
                              {{ $t("product.errors.circularUnitChainError") }}
                            </v-alert>
                          </v-col>
                        </v-row>

                        <!-- Button to create next unit conversion chain -->
                        <v-row justify="end">
                          <v-col cols="auto">
                            <wx-btn-standard
                              color="secondary"
                              class="cancel-btn"
                              @click="createNextUnitConversion"
                              :disabled="!validateLastUnitChainConversion()"
                              id="pendo-unit-chain-conversion-btn-1"
                            >
                              {{ $t("product.wizard.conversionBtn") }}
                            </wx-btn-standard>
                          </v-col>
                        </v-row>
                      </fieldset>
                      <!-- - - - - - - - - - - - Button to create first unit conversion chain - - - - - - - - - - - - - - -->
                      <v-row v-else justify="end">
                        <v-col>
                          {{ $t("product.wizard.countedUnitsConversionTitle") }}
                        </v-col>
                        <v-col cols="auto">
                          <wx-btn-standard 
                          color="secondary" 
                          class="cancel-btn" 
                          @click="createEmptyUnitConversion"
                          id="pendo-unit-chain-conversion-btn-2"
                          >
                            {{ $t("product.wizard.conversionBtn") }}
                          </wx-btn-standard>
                        </v-col>
                      </v-row>
                    </section>
                    <!----------------- Weight / Length / Volume Conversion ----------------->
                    <section class="product-target-row">
                      <fieldset class="inline-fields" v-if="weightLengthVolumeConversionExists">
                        <span>{{ $t("product.wizard.wlvConversionTitle") }}</span>
                        <v-row justify="space-between">
                          <v-col cols="auto">
                            <span class="font-italic text--disabled">{{
                              $t("product.wizard.wlvUnitConversionWarning")
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row v-if="missingAssociatedPuUnitsInChain.length > 0" justify="space-between">
                          <v-col cols="auto">
                            <span class="font-italic text--disabled">{{
                              $t("product.wizard.wlvUnitConversionDisabledWarning", {
                                missingUnits: formattedMissingUnits,
                              })
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-list>
                          <v-list-item>
                            <v-row align="center">
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <wx-text-field
                                  value="1"
                                  disabled
                                  :label="$t('product.wizard.quantity')"
                                ></wx-text-field>
                              </v-col>
                              <!-- Target base value for weight / length / volume -->
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <wx-autocomplete
                                  v-model="targetValueBaseUnit"
                                  :items="availableUnitNamesForWLVTargets"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('productionUnit.details.convertedUnitName')"
                                  :disabled="missingAssociatedPuUnitsInChain.length > 0"
                                  clearable
                                  @input="onWLVConversionTargetChange"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col cols="12" md="1" lg="1" xl="1" align="center">
                                <p><b>=</b></p>
                              </v-col>
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <!-- - - - - - - - - - - - -     Target Value     - - - - - - - - - - - - - - - -->
                                <wx-text-field
                                  ref="targetValue"
                                  v-model.number="targetValue"
                                  min="0"
                                  max="99999"
                                  :label="$t('product.details.targetValue')"
                                  type="number"
                                  :rules="[() => validateTargetValue()]"
                                  @change="revalidateTargetFields"
                                  @input="onWLVConversionTargetChange"
                                />
                              </v-col>
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <!-- - - - - - - - - - - -       Target Unit       - - - - - - - - - - - - - - -->
                                <wx-autocomplete
                                  v-model="targetUnit"
                                  :items="availableWeightLengthVolumeTargetUnits"
                                  :label="$t('product.details.targetUnit')"
                                  :rules="[() => validateTargetUnit()]"
                                  @change="onTargetUnitChange"
                                  item-text="name"
                                  item-value="id"
                                  validate-on-blur
                                  @input="onWLVConversionTargetChange"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col
                                class="d-flex field-col"
                                :class="{
                                  'justify-center': $vuetify.breakpoint.smAndDown,
                                  'justify-end': $vuetify.breakpoint.mdAndUp,
                                }"
                                cols="12"
                                md="1"
                                lg="1"
                                xl="1"
                              >
                                <wx-btn-standard
                                  color="transparent"
                                  class="mb-5 mr-0 pr-0 pl-0 ml-0"
                                  @click="removeWeightLengthVolumeConversion"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </wx-btn-standard>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list>
                      </fieldset>
                      <!-- - - - - - - - - - - - Weight / Length / Volume Converion Row with no data - - - - - - - - - - - - - - -->
                      <v-row v-else justify="end">
                        <v-col>
                          {{ $t("product.wizard.wlvConversionTitle") }}
                        </v-col>
                        <v-col cols="auto">
                          <wx-btn-standard
                            color="secondary"
                            class="cancel-btn"
                            @click="showWeightLengthVolumeConversion = true"
                            id="pendo-wlv-conversion-btn"
                          >
                            {{ $t("product.wizard.conversionBtn") }}
                          </wx-btn-standard>
                        </v-col>
                      </v-row>
                    </section>
                  </v-form>

                  <!-- Alert for broken target base value weight chains -->
                  <v-row justify="center" v-if="isWeightLengthVolumeTargetBroken">
                    <v-col cols="auto">
                      <v-alert bordered type="error" text outlined>
                        {{ brokenWeightLengthVolumeTargetErrorMessage }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <!-- - - - - - - - -       Giveaway Tolerance Section       - - - - - - - - - - - -->
        <v-card v-show="isProductRelatedToSF4ProductionUnits()" class="product-section-card">
          <v-card-text>
            <v-expansion-panels v-model="giveawayAccordionOpen" class="expansion-panel-style" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("product.wizard.giveaway") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="giveawayForm" lazy-validation>
                    <section class="product-target-row">
                      <fieldset class="inline-fields">
                        <v-row class="mx-0">
                          <span class="mr-2">{{ $t("product.wizard.targetSubSectionTitle") }}</span>
                          <wx-contextualized-help :tooltip-text="$t('product.wizard.targetSubSectionTooltip')" />
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="font-italic text--disabled">{{ giveawayTargetWarning }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="field-col">
                            <!-- - - - - - - - - - - - -     Target Value     - - - - - - - - - - - - - - - -->
                            <wx-text-field
                              ref="targetValue"
                              v-model.number="targetValue"
                              :label="$t('product.details.targetValue')"
                              :suffix="targetUnit"
                              disabled
                            />
                          </v-col>
                          <v-col class="field-col">
                            <!-- - - - - - - - - - - - -     Lower Tolerance Value     - - - - - - - - - - - - - - - -->
                            <wx-text-field
                              ref="lowerTolerance"
                              v-model.number="targetLowerTolerance"
                              min="0"
                              max="99999"
                              :label="$t('product.details.targetLowerTolerance')"
                              :suffix="targetUnit"
                              type="number"
                              :rules="[() => validateTargetLowerTolerance()]"
                              :disabled="!targetValue || !targetUnit"
                              @blur="revalidateTargetFields"
                            />
                          </v-col>
                          <v-col class="field-col">
                            <!-- - - - - - - - - - - - -     Upper Tolerance Value     - - - - - - - - - - - - - - - -->
                            <wx-text-field
                              ref="upperTolerance"
                              v-model.number="targetUpperTolerance"
                              min="0"
                              max="99999"
                              :label="$t('product.details.targetUpperTolerance')"
                              :suffix="targetUnit"
                              type="number"
                              :rules="[() => validateTargetUpperTolerance()]"
                              :disabled="!targetValue || !targetUnit"
                              @blur="revalidateTargetFields"
                            />
                          </v-col>
                        </v-row>
                      </fieldset>
                    </section>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>
      <form-footer-actions class="pb-0 mb-0" :click-submit="submit" cancel-to="toProducts" />
      <!-- </v-form> -->
    </wx-form-container>
  </fragment>
</template>

<script>
import WxFormContainer from "@/components/ui/WxFormContainer";
import FormFooterActions from "@/components/ui/FormFooterActions";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxTextField from "@/components/ui/WxTextField";
import WxSelect from "@/components/ui/WxSelect";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import ProductService from "@/components/product/ProductService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import Validations from "@/components/validations";
import { mapActions, mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import PackageFeatures from "@/components/PackageFeatures";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp";
import ProductObjectives from "@/components/product/ProductObjectives";
import { compareProductionUnitsByName } from "@/components/SortUtils";
import ConversionUnitChainValidations from "@/components/product/ConversionUnitChainValidations";
import { getUnitType } from "@/components/user/UserPreferencesService";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";

export default {
  name: "ProductWizard",
  components: {
    WxFormContainer,
    FormFooterActions,
    WxBtnStandard,
    WxSelect,
    WxTextField,
    WxAutocomplete,
    WxContextualizedHelp,
  },
  props: {
    initialProduct: {
      type: Object,
      default: null,
    },
    productionUnits: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panels: [],
      detailsAccordionOpen: 0,
      giveawayAccordionOpen: null,

      sku: null,
      name: null,
      category: null,
      isCreation: true,
      targetLowerTolerance: null,
      targetUpperTolerance: null,
      associatedProductionUnits: [
        {
          associationId: null,
          productionUnit: {},
          conversionFactorCount: 1,
          conversionFactorQuantity: 1,
          objectives: [
            {
              objectiveType: ProductObjectives.rateObjectiveName,
              objectiveValue: 1,
            },
            {
              objectiveType: ProductObjectives.giveawayObjectiveName,
              objectiveValue: null,
            },
          ],
        },
      ],
      skuError: false,
      skuErrorMessages: [],
      skuMaxLength: Validations.productSkuMaxLength,
      productNameMaxLength: Validations.productNameMaxLength,
      productCategoryMaxLength: Validations.productCategoryMaxLength,
      conversionFactorMaxLength: Validations.productConversionFactorMaxLength,
      productObjectiveMaxLength: Validations.productObjectiveMaxLength,

      unitConversions: [],

      targetValue: null, // target weight / length / volume value
      targetUnit: null, // target weight / length / volume unit, ex. g, kg, mL etc
      targetValueBaseUnit: null, // cans, bags, etc

      showWeightLengthVolumeConversion: false,

      showCircularDependencyError: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId"]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    currentProductionUnits() {
      let puList = [...this.productionUnits];
      puList.sort(compareProductionUnitsByName);
      return puList;
    },
    availableWeightLengthVolumeTargetUnits() {
      let allOptions = [{ id: "-", name: this.$t("common.none") }];
      let productAvailableTargetUnits = Validations.getProductAvailableTargetUnits();
      allOptions.push(...productAvailableTargetUnits);
      return allOptions;
    },
    productTitle() {
      let description = this.name?.length > 100 ? this.name?.slice(0, 97) + "..." : this.name;
      return this.name ? description : this.sku;
    },
    missingAssociatedPuUnitsInChain() {
      const unitChain = ConversionUnitChainValidations.getUnitChain(this.unitConversions);
      return this.associatedProductionUnits
        .map((associatedPu) => associatedPu.productionUnit.converted_unit_name)
        .filter((convertedUnitName) => !!convertedUnitName && !unitChain.includes(convertedUnitName));
    },
    formattedMissingUnits() {
      return this.missingAssociatedPuUnitsInChain.map((unit) => getUnitName(unit).name).join(", ");
    },
    availableUnitNamesForWLVTargets() {
      const allOptions = [];
      const unitChain = ConversionUnitChainValidations.getUnitChain(this.unitConversions);
      let availableUnits = this.availableUnitNames().map((unit) => {
        return {
          ...unit,
          disabled: this.missingAssociatedPuUnitsInChain.length > 0 ? true : !unitChain.includes(unit.id),
        };
      });
      allOptions.push(...availableUnits);
      return allOptions;
    },
    weightLengthVolumeConversionExists() {
      return (this.targetValue && this.targetUnit) || this.showWeightLengthVolumeConversion;
    },
    giveawayTargetWarning() {
      if (!this.targetUnit) return null;
      switch (getUnitType(this.targetUnit.toLowerCase())) {
        case "weight_unit":
          return this.$t("product.wizard.giveawayTargetWarning.weight");
        case "volume_unit":
          return this.$t("product.wizard.giveawayTargetWarning.volume");
        case "length_unit":
          return this.$t("product.wizard.giveawayTargetWarning.length");
      }
      return null;
    },
    brokenTargets() {
      const pus = this.associatedProductionUnits.map((associatedPU) => {
        return {
          name: associatedPU?.productionUnit?.name,
          convertedUnitName: associatedPU?.productionUnit?.converted_unit_name,
        };
      });

      const baseUnit = !this.targetValueBaseUnit || this.targetValueBaseUnit === "-" ? null : this.targetValueBaseUnit;
      return ConversionUnitChainValidations.detectBrokenTargets(pus, baseUnit, this.unitConversions);
    },
    isWeightLengthVolumeTargetBroken() {
      return this.brokenTargets.isBroken;
    },
    brokenWeightLengthVolumeTargetErrorMessage() {
      if (!this.isWeightLengthVolumeTargetBroken) return null;
      const missingUnitLabelsTransformed = this.brokenTargets.missingUnits
        .map((unit) => {
          const name = Validations.getProductionUnitAvailableUnits().find((puUnit) => puUnit.id === unit)?.name;
          if (!name) return null;
          return `"${name}"`;
        })
        .filter((name) => !!name)
        .join(",");
      const unitLabelTransformed = Validations.getProductionUnitAvailableUnits().find(
        (puUnit) => puUnit.id === this.brokenTargets.baseUnit,
      )?.name;
      return this.$t("product.errors.targetProductConversionError", {
        unitName: unitLabelTransformed,
        targetUnit: this.targetUnit,
        missingUnits: missingUnitLabelsTransformed,
      });
    },
  },
  watch: {
    initialProduct() {
      if (this.initialProduct) {
        this.isCreation = false;
        this.sku = this.initialProduct.sku;
        this.skuMaxLength = undefined;
        this.category = this.initialProduct.category;
        this.name = this.initialProduct.description;

        // Production Units
        this.setAssociatedProductionUnits(this.initialProduct.associated_production_units);

        // Conversion chain
        this.setUnitConversionChain(this.initialProduct.unit_conversions);

        // Weight / Length / Volume target
        this.targetValue = this.initialProduct.target?.value;
        this.targetUnit = this.initialProduct.target?.unit;
        this.targetValueBaseUnit = this.initialProduct.target?.base_unit;

        // Giveaway Tolerance
        this.targetLowerTolerance = this.initialProduct.target?.tolerance?.lower_value;
        this.targetUpperTolerance = this.initialProduct.target?.tolerance?.upper_value;
      }
    },
    isWeightLengthVolumeTargetBroken(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.targetValueBaseUnit = null;
      }
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    onWLVConversionTargetChange() {
      this.giveawayAccordionOpen = 0; // open the giveaway section if there is one.
    },
    availableUnitNames(chainIndex) {
      if (chainIndex === null || chainIndex === undefined) {
        return Validations.getProductionUnitAvailableUnits().filter((unit) => unit.isUnitChainConversion);
      }
      if (chainIndex === 0) {
        const availableUnits = this.associatedProductionUnits
          .map((associatedPu) => associatedPu.productionUnit.converted_unit_name)
          .filter((convertedUnitName) => !!convertedUnitName);
        return Validations.getProductionUnitAvailableUnits().filter((unit) => {
          return unit.isUnitChainConversion && availableUnits.indexOf(unit.id) >= 0;
        });
      }
      return Validations.getProductionUnitAvailableUnits().filter((unit) => unit.isUnitChainConversion);
    },
    getRouteBack() {
      return RouteService.toProducts();
    },
    isProductionUnitSelected(associatedPU) {
      return associatedPU?.productionUnit?.id;
    },
    getPackages(associatedPU) {
      let puPackages = associatedPU?.productionUnit?.packages?.filter((p) => String(p))?.sort();
      return puPackages?.at(-1) ?? null;
    },
    validateSku() {
      // Sku edition is disabled this.isCreation
      if (this.isCreation) {
        if (!this.sku) {
          return this.$t("product.errors.noSku");
        }
        if (this.sku.trim().length > this.skuMaxLength) {
          return this.$t("product.errors.skuTooLong", {
            maxLength: this.skuMaxLength,
          });
        }
      }
      this.skuError = false;
      this.skuErrorMessages = [];
      return true;
    },
    validateName() {
      if (!!this.name && this.name.trim().length > this.productNameMaxLength) {
        return this.$t("product.errors.nameTooLong", {
          maxLength: this.productNameMaxLength,
        });
      }
      return true;
    },
    validateCategory() {
      if (!!this.category && this.category.trim().length > this.productCategoryMaxLength) {
        return this.$t("product.errors.categoryTooLong", {
          maxLength: this.productCategoryMaxLength,
        });
      }
      return true;
    },
    isSelectedTargetUnitSetToNone() {
      return this.targetUnit === "-";
    },
    onTargetUnitChange() {
      if (this.isSelectedTargetUnitSetToNone()) {
        this.targetValue = null;
        this.targetLowerTolerance = null;
        this.targetUpperTolerance = null;
      }
      this.revalidateTargetFields();
    },
    revalidateTargetFields() {
      this.$refs.targetValue.validate(true);
      this.$refs.lowerTolerance?.validate(true);
      this.$refs.upperTolerance?.validate(true);
    },
    validateTargetUnit() {
      if (this.isSelectedTargetUnitSetToNone()) return true;

      if (this.targetValue && !this.targetUnit) {
        return this.$t("product.errors.noTargetUnit");
      }

      if (this.targetUnit && !Validations.isProductTargetUnitValid(this.targetUnit)) {
        const supportedValues = this.availableWeightLengthVolumeTargetUnits.map((unit) => unit.name).join();
        return this.$t("product.errors.invalidTargetUnit", { supportedValues: supportedValues });
      }
      return true;
    },
    validateTargetValue() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetValue != null && this.targetValue < 0) {
          return this.$t("product.errors.unsupportedTargetValueForUnitNone");
        }
        return true;
      }

      if (this.targetUnit && this.targetValue == null) {
        return this.$t("product.errors.noTargetValue");
      }

      if (this.targetValue != null && this.targetValue == 0) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetValue && !Validations.isProductTargetValueValid(this.targetValue)) {
        return this.$t("product.errors.invalidTargetValue");
      }
      return true;
    },
    validateTargetLowerTolerance() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetLowerTolerance != null && this.targetLowerTolerance > 0) {
          return this.$t("product.errors.unsupportedTargetLowerToleranceForUnitNone");
        }
        return true;
      }

      if (this.targetUpperTolerance && !this.targetLowerTolerance) {
        return this.$t("product.errors.noTargetLowerToleranceValue");
      }

      if (this.targetLowerTolerance && !Validations.isProductTargetValueValid(this.targetLowerTolerance)) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetLowerTolerance > this.targetValue) {
        return this.$t("product.errors.invalidTargetLowerToleranceValue");
      }
      return true;
    },
    validateTargetUpperTolerance() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetUpperTolerance != null && this.targetUpperTolerance > 0) {
          return this.$t("product.errors.unsupportedTargetUpperToleranceForUnitNone");
        }
        return true;
      }

      if (this.targetLowerTolerance && !this.targetUpperTolerance) {
        return this.$t("product.errors.noTargetUpperToleranceValue");
      }

      if (this.targetUpperTolerance && !Validations.isProductTargetValueValid(this.targetUpperTolerance)) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetUpperTolerance < this.targetValue && this.targetUpperTolerance) {
        return this.$t("product.errors.invalidTargetUpperToleranceValue");
      }
      return true;
    },
    validateConversionFactorCount(conversionFactorCount) {
      if (
        isNaN(conversionFactorCount) ||
        conversionFactorCount <= 0 ||
        conversionFactorCount > 9999999999 ||
        !Validations.isProductConversionFactorValid(conversionFactorCount)
      ) {
        return this.$t("product.errors.invalidConversionFactorCapturedQuantity");
      }
      return true;
    },
    validateConversionFactorRealQuantity(conversionFactorQuantity) {
      if (
        isNaN(conversionFactorQuantity) ||
        conversionFactorQuantity <= 0 ||
        conversionFactorQuantity > 9999999999 ||
        !Validations.isProductConversionFactorValid(conversionFactorQuantity)
      ) {
        return this.$t("product.errors.invalidConversionFactorRealQuantity");
      }
      return true;
    },
    createConversionFactor(associatedProductionUnit) {
      return associatedProductionUnit.conversionFactorCount + ":" + associatedProductionUnit.conversionFactorQuantity;
    },
    validateRateObjectiveValue(objectiveValue) {
      let parsedObjectiveValue = parseFloat(objectiveValue);
      if (
        isNaN(parsedObjectiveValue) ||
        parsedObjectiveValue <= 0 ||
        !Validations.isProductRateObjectiveValueValid(objectiveValue)
      ) {
        return this.$t("product.errors.invalidRateObjective");
      }
      return true;
    },
    validateGiveawayObjectiveValue(objectiveValue) {
      if (
        isNaN(objectiveValue) ||
        objectiveValue <= 0 ||
        objectiveValue > 100 ||
        !Validations.isProductGiveawayObjectiveValueValid(objectiveValue)
      ) {
        return this.$t("product.errors.invalidGiveawayObjective");
      }

      return true;
    },
    // validate the associatedProductionUnit
    submit() {
      if (this.isCreation) {
        this.create();
      } else {
        this.update();
      }
    },
    isValid() {
      let detailsForm = this.$refs.detailsForm.validate();
      let productionUnitsForm = this.$refs.productionUnitsForm.validate();
      let unitConversionForm = this.$refs.unitConversionForm.validate();
      let giveawayForm = this.$refs.giveawayForm?.validate();
      let isValidForms = detailsForm && productionUnitsForm && unitConversionForm && giveawayForm;

      // Need to check that forms evaluate to false so that null and undefined do not pass the check
      if (detailsForm === false) {
        this.panels.push(0);
      }
      if (productionUnitsForm === false) {
        this.panels.push(1);
      }
      if (unitConversionForm === false || this.showCircularDependencyError || this.isWeightLengthVolumeTargetBroken) {
        this.panels.push(2);
      }
      if (giveawayForm === false) {
        this.panels.push(3);
      }
      return isValidForms && !this.showCircularDependencyError && !this.isWeightLengthVolumeTargetBroken;
    },
    create() {
      const isValid = this.isValid();
      if (isValid) {
        const associatedProductionUnits = this.createAssociatedProductionUnits();
        const target = this.createWeightLengthVolumeProductTarget();
        const unitChainConversions = this.createUnitChainConversion();
        const newProduct = {
          sku: this.sku,
          category: this.category,
          description: this.name,
          target: target,
          unit_conversions: unitChainConversions,
          associated_production_units: associatedProductionUnits,
        };
        ProductService.createProduct(this.activeFactoryId, newProduct)
          .then((response) => this.handleProductCreationResponse(response, newProduct))
          .catch((error) => this.handleProductCreationError(error.response));
      }
    },
    update() {
      const isValid = this.isValid();
      if (isValid) {
        let associatedProductionUnits = this.createAssociatedProductionUnits();
        const target = this.createWeightLengthVolumeProductTarget();
        const unitChainConversions = this.createUnitChainConversion();
        const updatedProduct = {
          id: this.initialProduct.id,
          category: this.category,
          description: this.name,
          target: target,
          unit_conversions: unitChainConversions,
          associated_production_units: associatedProductionUnits,
        };
        ProductService.updateProduct(updatedProduct)
          .then((response) => this.handleProductUpdateResponse(response))
          .catch((error) => this.handleProductUpdateError(error.response));
      }
    },
    createUnitChainConversion() {
      return {
        units: this.unitConversions.map((unitConversion) => {
          return {
            from_unit: unitConversion.from_unit,
            conversion_factor: unitConversion.from_factor + ":" + unitConversion.to_factor,
            to_unit: unitConversion.to_unit,
          };
        }),
      };
    },
    createWeightLengthVolumeProductTarget() {
      let target = null;
      if (!this.isSelectedTargetUnitSetToNone()) {
        let targetTolerance = null;
        if (
          this.targetLowerTolerance != null &&
          this.targetLowerTolerance > 0 &&
          this.targetUpperTolerance != null &&
          this.targetUpperTolerance > 0
        ) {
          targetTolerance = {
            lower_value: this.targetLowerTolerance,
            upper_value: this.targetUpperTolerance,
          };
        }
        if (this.targetValue != null && this.targetUnit != null) {
          target = {
            value: this.targetValue,
            unit: this.targetUnit,
            tolerance: targetTolerance,
            base_unit: this.targetValueBaseUnit === "-" ? null : this.targetValueBaseUnit,
          };
        }
      }
      return target;
    },
    createAssociatedProductionUnits() {
      return this.associatedProductionUnits.map((associatedPU) => ({
        association_id: associatedPU.associationId,
        production_unit_id: associatedPU.productionUnit.id,
        conversion_factor: this.createConversionFactor(associatedPU),
        objectives: this.buildProductionUnitObjectives(associatedPU),
      }));
    },
    buildProductionUnitObjectives(associatedProductionUnit) {
      return associatedProductionUnit.objectives
        .filter((objective) => objective.objectiveValue)
        .map((objective) => ({
          objective_type: objective.objectiveType,
          value: objective.objectiveValue,
        }));
    },
    setAssociatedProductionUnits(associatedProductionUnits) {
      this.associatedProductionUnits.splice(0, 1);
      this.associatedProductionUnits = associatedProductionUnits.map((associatedPU) => ({
        associationId: associatedPU.association_id,
        productionUnit: this.getProductionUnitById(associatedPU.production_unit_id),
        conversionFactorCount: Number(associatedPU.conversion_factor.split(":", 2)[0]),
        conversionFactorQuantity: Number(associatedPU.conversion_factor.split(":", 2)[1]),

        objectives: this.getObjectives(associatedPU.objectives),
      }));
    },
    setUnitConversionChain(productUnitConversions) {
      this.unitConversions =
        productUnitConversions?.units?.map((unitConversion) => {
          const factors = unitConversion.conversion_factor.split(":", 2);
          return {
            from_unit: unitConversion.from_unit,
            from_factor: Number(factors[0]),
            to_factor: Number(factors[1]),
            to_unit: unitConversion.to_unit,
          };
        }) ?? [];
    },
    getProductionUnitById(productionUnitId) {
      return this.productionUnits.find((pu) => pu.id === productionUnitId);
    },
    getConvertedUnitName(associatedPU) {
      if (associatedPU && associatedPU.productionUnit && associatedPU.productionUnit.converted_unit_name) {
        return ProductionUnitService.getUnitName(associatedPU.productionUnit.converted_unit_name);
      }
      return ProductionUnitService.getUnitName(null); // used to get the default one.
    },
    getRateObjectiveUnitName(associatedPU) {
      return this.getConvertedUnitName(associatedPU) + "/h";
    },
    isRateObjective(objectiveType) {
      return objectiveType === ProductObjectives.rateObjectiveName;
    },
    isGiveawayObjective(objectiveType) {
      return objectiveType === ProductObjectives.giveawayObjectiveName;
    },
    getObjectives(associatedPUObjectives) {
      this.createEmptyRateObjectiveIfNecessary(associatedPUObjectives);
      this.createEmptyGiveawayObjectiveIfNecessary(associatedPUObjectives);

      return associatedPUObjectives.map((objective) => ({
        objectiveType: objective.objective_type,
        objectiveValue: objective.value,
      }));
    },
    createEmptyRateObjectiveIfNecessary(associatedPUObjectives) {
      if (!associatedPUObjectives.some((obj) => obj.objective_type === ProductObjectives.rateObjectiveName)) {
        associatedPUObjectives.push({
          objective_type: ProductObjectives.rateObjectiveName,
          value: null,
        });
      }
    },
    createEmptyGiveawayObjectiveIfNecessary(associatedPUObjectives) {
      if (!associatedPUObjectives.some((obj) => obj.objective_type === ProductObjectives.giveawayObjectiveName)) {
        associatedPUObjectives.push({
          objective_type: ProductObjectives.giveawayObjectiveName,
          value: null,
        });
      }
    },
    isRateObjectiveAuthorized(productionUnitId) {
      return this.puHasRequiredFeature(productionUnitId, PackageFeatures.performance);
    },
    isGiveawayObjectiveAuthorized(productionUnitId) {
      return this.puHasRequiredFeature(productionUnitId, PackageFeatures.giveaway);
    },
    handleProductCreationResponse(httpResponse, newProduct) {
      if (httpResponse.status === 201) {
        this.showOperationSuccess(this.$t("product.successfullyCreated", { sku: newProduct.sku }));
        this.close();
      }
    },
    handleProductUpdateResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.showOperationSuccess(this.$t("product.successfullyUpdated", { sku: this.sku }));
        this.close();
      }
    },
    handleProductCreationError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      if (httpResponse.status === 409) {
        this.skuErrorMessages = errorMessage;
        this.skuError = true;
      } else {
        this.showOperationError(errorMessage);
      }
    },
    handleProductUpdateError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      this.showOperationError(errorMessage);
    },
    getErrorMessage(code, args, message) {
      switch (code) {
        case "CS_PROD_POST_20001":
          return this.$t("product.errors.duplicateSku");
        case "DSH_PROD_PUT_10002": {
          const regex = /sku '(.*)' is currently selected on Production Unit '(.*)';/gm;
          let matches = regex.exec(message);
          let sku = !!matches && matches.length >= 3 ? matches[1] : "";
          let puName = !!matches && matches.length >= 3 ? matches[2] : "";
          return this.$t("product.errors.productInSkuPreventingAssociationDeletion", { sku: sku, puName: puName });
        }
      }
      if (code === "CS_PROD_POST_20001") return this.$t("product.errors.duplicateSku");
      else return this.$t("common.errors.default", { code: code });
    },
    addNewLine() {
      this.createEmptyAssociatedProductionUnit();
    },
    createEmptyAssociatedProductionUnit() {
      this.associatedProductionUnits.push({
        associationId: null,
        productionUnit: {},
        conversionFactorCount: 1,
        conversionFactorQuantity: 1,
        objectives: [
          {
            objectiveType: ProductObjectives.rateObjectiveName,
            objectiveValue: 1,
          },
          {
            objectiveType: ProductObjectives.giveawayObjectiveName,
            objectiveValue: null,
          },
        ],
      });
    },
    isProductAssociatedToOneProductionUnit() {
      // The product will be associated at least to one production unit.
      return this.associatedProductionUnits.length === 1;
    },
    deleteLine(index) {
      this.associatedProductionUnits.splice(index, 1);
    },
    onSelectedProductionUnit(selectedProductionUnit, associatedPUIndex) {
      let associatedPU = this.associatedProductionUnits[associatedPUIndex];
      associatedPU.productionUnit = selectedProductionUnit;
    },
    isProductionUnitMissing(selectedProductionUnitId) {
      if (!selectedProductionUnitId) return this.$t("product.errors.missingProductionUnit");
      return true;
    },
    isSelectedProductionUnitDuplicated(selectedProductionUnitId) {
      let productionUnitDuplicatedCount = this.associatedProductionUnits
        .map((associatedPU) => associatedPU?.productionUnit?.id)
        .filter((productionUnitId) => {
          return productionUnitId === selectedProductionUnitId;
        }).length;
      if (productionUnitDuplicatedCount > 1) return this.$t("product.errors.duplicatedProductionUnit");
      return true;
    },
    close() {
      this.panels = [];
      this.sku = null;
      this.name = null;
      this.category = null;
      this.isCreation = true;
      this.targetValue = null;
      this.targetUnit = null;
      this.targetLowerTolerance = null;
      this.targetUpperTolerance = null;
      this.associatedProductionUnits = [];
      this.unitConversions = [];
      this.targetValue = null;
      this.targetUnit = null;
      this.targetValueBaseUnit = null;
      this.showWeightLengthVolumeConversion = false;
      this.showCircularDependencyError = false;
      this.$refs.detailsForm.resetValidation();
      this.$refs.productionUnitsForm.resetValidation();
      this.$refs.unitConversionForm.resetValidation();
      this.$refs.giveawayForm?.resetValidation();
      this.$router.push(this.getRouteBack());
    },
    createEmptyUnitConversion() {
      this.unitConversions.push({
        from_unit: null,
        from_factor: null,
        to_unit: null,
        to_factor: null,
      });
    },
    createNextUnitConversion() {
      const lastConversion = this.unitConversions[this.unitConversions.length - 1];
      // The destination unit of the last conversion is the source unit of the next conversion
      const fromUnit = lastConversion.to_unit;
      this.unitConversions.push({
        from_unit: fromUnit,
        from_factor: null,
        to_unit: null,
        to_factor: null,
      });
    },
    removeUnitConversion() {
      if (this.unitConversions.length > 0) {
        this.unitConversions.pop();
      }
    },
    removeWeightLengthVolumeConversion() {
      this.targetValue = null;
      this.targetUnit = null;
      this.targetValueBaseUnit = null;
      this.targetLowerTolerance = null;
      this.targetUpperTolerance = null;

      this.showWeightLengthVolumeConversion = false;
      this.$refs.targetValue.resetValidation();
    },
    isProductRelatedToSF4ProductionUnits() {
      return this.associatedProductionUnits.some((pu) => pu?.productionUnit?.packages?.includes("SF4"));
    },
    validateUnitConversion(unitConversionInput, otherUnitConversionInput) {
      if (!unitConversionInput || unitConversionInput.trim().length === 0) {
        return this.$t("product.errors.noUnitConversionError");
      } else if (unitConversionInput === otherUnitConversionInput) {
        return this.$t("product.errors.sameUnitConversionError");
      }
      return true;
    },
    validateFactorConversion(factorConversion) {
      if (!factorConversion || typeof factorConversion !== "number" || factorConversion <= 0) {
        return this.$t("product.errors.invalidFactorConversionError");
      }

      return true;
    },
    validateLastUnitChainConversion() {
      const lastConversion = this.unitConversions[this.unitConversions.length - 1];
      let fromUnitConversionValidation = this.validateUnitConversion(lastConversion.from_unit, lastConversion.to_unit);
      let toUnitConversionValidation = this.validateUnitConversion(lastConversion.to_unit, lastConversion.from_unit);
      let fromFactorConversionValidation = this.validateFactorConversion(lastConversion.from_factor);
      let toFactorConversionValidation = this.validateFactorConversion(lastConversion.to_factor);

      if (
        typeof fromUnitConversionValidation === "string" ||
        typeof toUnitConversionValidation === "string" ||
        typeof fromFactorConversionValidation === "string" ||
        typeof toFactorConversionValidation === "string" ||
        this.detectCircularDependencies()
      ) {
        return false;
      }

      return true;
    },
    detectCircularDependencies() {
      const isCircular = ConversionUnitChainValidations.detectCircularUnitChain(this.unitConversions);
      this.showCircularDependencyError = isCircular;
      return isCircular;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-target-row,
.convert-factor-objective-container {
  .inline-fields {
    width: 100%;
  }
}

.product-target-row {
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.convert-factor-objective-container {
  display: flex;
  padding-bottom: var(--box-padding-admin);

  .objectives-list {
    flex-shrink: 0;
  }
}

.associated-pu-list {
  .pu-item {
    .legend-header {
      display: flex;

      .v-btn {
        margin-top: 0;
        margin-left: auto; // aligned to right
      }
    }
  }
}

// Unknowen containers. Could be obsolete (mDube, may 2022)
.product-tolerance-value {
  width: 45%;
}

.form-style {
  background-color: transparent;
  &.wizard {
    max-width: 850px;
  }
}

.product-section-card {
  background-color: var(--color-element-layer1);
}

.expansion-panel-style {
  .v-expansion-panel-header {
    background-color: var(--color-element-layer1);
  }
  .v-expansion-panel-content {
    background-color: var(--color-element-layer1);
  }
}

.conversion-description {
  color: #71797e;
}
</style>
